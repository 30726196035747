import React from "react";
import BaseLayout from "layouts/base-layout";
import "scss/main.scss";
import "scss/vendor/carousels.scss";

import renderModules from "helpers/render-modules";
import ProductsGrid from "components/products-grid";
import ProductsCategories from "components/products-categories";
import MixedCategories from "components/mixed-categories";

export default function Collection(props) {
  console.log(props);
  const { settings, collections, collection } = props.pageContext;
  const modules = collection?.content?.modules;

  const oCollections = () => collections.filter((_coll) => _coll.handle !== "all");
  const sCollections = collections.sort((a, b) => a?.handle > b?.handle ? 1 : -1);

  if(!collection || !collections) return <div>Loading...</div>;

  if (collection?.handle === "all") {
    return (
      <BaseLayout
        data={settings}
        meta={collection?.content.meta}
      >
        <ProductsCategories collection={collection} collections={sCollections} />
        <MixedCategories collection={collection} collections={sCollections} />

        { oCollections()?.map((_collection, _key) => <ProductsGrid key={_key} collection={_collection} /> ) }
        { modules && renderModules(modules) }
      </BaseLayout>
    );
  }

  return (
    <BaseLayout
      data={settings}
      meta={collection?.meta}
    >
      <ProductsCategories collection={collection} collections={sCollections} />
      <MixedCategories collection={collection} collections={sCollections} />

      <ProductsGrid collection={collection} />
      { modules && renderModules(modules) }
    </BaseLayout>
  );
}
