import React, { useState, useEffect } from "react";
import {
  Container, Card, Button, Col
} from "react-bootstrap";
import {
  mixedCategories, mcContent ,mcRow, mcCard, mcBody,  mcImg,
  mcTitle, cTitle, cSubtitle, cBtn, btnIsActive
} from "./style.mod.scss";

import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Pagination]);

const MixedCategories = ({ collection, collections }) => {
  const [activeHandle, setActiveHandle] = useState(null);

  useEffect(() => {
    setActiveHandle(collections[0]?.handle);
  }, []);

  const activeClass = (handle) => activeHandle === handle ? btnIsActive : "";

  const fCollections = () => collections.filter((_coll) => _coll.handle !== "all");
  const activeCollection = () => fCollections().find((_col) => _col.handle === activeHandle);
  const activeProducts = () => activeCollection()?.products?.flatMap((_prod) => _prod.variants);

  if (!collection || collection.handle !== "all") return <></>;

  return (
    <div className={mixedCategories}>
      <Container>
        <div className={mcContent}>
          <Col xs="12" md="12" lg="4">
            <div>
              <p className={cTitle}>Mixed Sets</p>
              <p className={cSubtitle}>Was soll dein Set beinhalten?</p>
              <div>
                {
                  fCollections().map((collection) => (
                    <Button
                      className={`${cBtn} ${activeClass(collection.handle)}`}
                      key={collection.handle}
                      onClick={() => setActiveHandle(collection.handle)}
                    >
                      { collection.title }
                    </Button>
                  ))
                }
              </div>
            </div>
          </Col>
          <Col xs="12" md="12" lg="8">
            <Swiper
              className={mcRow}
              slidesPerView={2.2}
              spaceBetween={25}
              direction="horizontal"
              breakpoints={{
                767: { slidesPerView: 3.4 },
                1200: { slidesPerView: 4 },
              }}
              pagination={{ clickable: true }}
            >
              {
                activeProducts()?.map(({ data }, index) => (
                  <SwiperSlide key={index}>
                    <div key={index}>
                      <Card className={mcCard} >
                        <Card.Img className={mcImg} variant="top" src={data.image} />
                        <Card.Body className={mcBody}>
                          <Card.Title className={mcTitle}>{data.title}</Card.Title>
                        </Card.Body>
                      </Card>
                    </div>
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </Col>
        </div>

      </Container>
    </div>
  );
};

export default MixedCategories;
