import React, { useState, useEffect } from "react";
import {
  Container, Card, ButtonGroup, Button,
  // OverlayTrigger, Tooltip
} from "react-bootstrap";
import {
  productsGrid, productsGridRow, productsGridCard, productsGridBody,  productsGridImg,
  productsGridTitle, productsGridColorBtn, shopBtn, btnActive,
  productsGridPrice, pTitle, desktopGrid, desktopGridItem, desktopItemWrapper,
  headerCol, priceRow,
} from "./style.mod.scss";
import Link from "components/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { ser, trackImpressions, trackProductClick } from "helpers/analytics";

const collectionConfig = {
  "wok": {
    "dynamic": ["Coating"],
    "static": ["Handle"]
  }
};

const Product = ({ product, key }) => {
  const [selected, setSelected] = useState(0);
  const variant = product.variants[selected];

  return (
    <div
      data-track-impression="true"
      data-track-props={ser({
        ecommerce: {
          currencyCode: "EUR",
          impressions: [
            {
              name: variant.data.title,
              id: variant.data.sku,
              price: variant.data.price,
              brand: "MyOlav",
              category: product.collectionHandle,
              variant: variant?.handle?.current,
              list: "Collection Page",
              position: key,
            }
          ]
        }
      })}
      className={desktopItemWrapper}
    >
      <Card className={productsGridCard}>
        <Card.Img className={productsGridImg} variant="top" src={variant?.data.image} />
        <Card.Body className={productsGridBody}>
          <Card.Title className={productsGridTitle}>{variant?.data.title}
          </Card.Title>
          <div className={headerCol}>
            <div className={priceRow}>
              <p className={productsGridPrice}>&euro;{variant?.data.price}</p>
              <ButtonGroup>
                {
                  product.variants.length > 1 && product.variants?.map( (variant, index) => (
                    <Button
                      key={index}
                      variant="secondary"
                      className={`${productsGridColorBtn} ${selected === index ? btnActive : ""}`}
                      onClick={() => setSelected(index) }
                      active={selected === index}
                      style={{ backgroundColor: index == 0 ? "black" : "#BCBCBC" }}
                    />
                    /* <OverlayTrigger
                      key={index}
                      placement="bottom"
                      // defaultShow={true}
                      overlay={
                        <Tooltip id="bottom">
                          { product.handle }
                        </Tooltip>
                      }
                    >
                    </OverlayTrigger> */
                  ))
                }
              </ButtonGroup>
            </div>
            <Card.Text></Card.Text>
            <Link to={`/products/${product.handle}/${variant?.handle?.current}`}>
              <button
                onClick={() => trackProductClick({
                  product, variant, key
                })}
                className={shopBtn}
                variant="primary"
              >
              SHOP NOW
              </button>
            </Link>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

const ProductsGrid = ({ collection }) => {
  const repackageProducts = (collection) => {
    let newProducts = [];
    if (!collection || !collection?.products) return newProducts;

    const remove = (arr, el) => {
      const index = arr.indexOf(el);
      if (index > -1) arr.splice(index, 1);
    };

    collection.products.forEach((product) => {
      product.variants.forEach((variant) => {
        newProducts.push({
          collectionHandle: collection.handle,
          handle: product.handle,
          options: product.options,
          variant: variant,
          variants: [variant],
        });
      });
    });

    const config = collectionConfig[collection?.handle];
    if (!config) return newProducts;

    newProducts.forEach((product) => {
      const {
        variants,
        variant
      } = product;
      const selectedOpts = variant.data.selected.filter((opt) => config.static == opt.name);

      newProducts.forEach((_product) => {
        let different = true;

        selectedOpts.forEach((opt) => {
          let prodOpt = _product.variant.data.selected.find((_opt) => _opt.name == opt.name);
          if (!prodOpt) return;
          different = different && prodOpt.value !== opt.value;
        });

        if (product !== _product && !different) {
          variants.push(_product.variant);
          remove(newProducts, _product);
        }
      });
    });

    return newProducts;
  };

  const products = repackageProducts(collection);

  useEffect(() => {
    trackImpressions({ select: productsGrid });
  });

  return (
    <div className={productsGrid}>
      <Container>
        <p className={pTitle}>{ collection.title }</p>
        <div className={`${productsGridRow} ${desktopGrid}`}>
          {
            products?.map((_prod, key) => (
              <div className={desktopGridItem} key={key}>
                <Product product={_prod} key={key} />
              </div>
            ))
          }
        </div>
        <Swiper
          slidesPerGroup={1}
          spaceBetween={25}
          className={productsGridRow}
          slidesPerView="1.4"
          centeredSlides={true}
          pagination={{ clickable: true }}
        >
          {
            products?.map((_prod, key) => (
              <SwiperSlide key={key}>
                <Product product={_prod} key={key} />
              </SwiperSlide>
            ))
          }
        </Swiper>
      </Container>
    </div>
  );
};

export default ProductsGrid;
