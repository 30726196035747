// extracted by mini-css-extract-plugin
export var productsGrid = "style-mod--products-grid--3tfqN";
export var pTitle = "style-mod--p-title--3ghud";
export var productsGridRow = "style-mod--products-grid-row--2CBi4";
export var desktopGrid = "style-mod--desktop-grid--354KP";
export var desktopGridItem = "style-mod--desktop-grid-item--2fiyp";
export var desktopItemWrapper = "style-mod--desktop-item-wrapper--1Vd-H";
export var productsGridBody = "style-mod--products-grid-body--1RHsd";
export var productsGridCard = "style-mod--products-grid-card--370og";
export var productsGridImg = "style-mod--products-grid-img--2z-Sz";
export var productsGridTitle = "style-mod--products-grid-title--zIgHY";
export var headerCol = "style-mod--header-col--2uRxD";
export var priceRow = "style-mod--price-row--rqAOZ";
export var productsGridPrice = "style-mod--products-grid-price--3VI5j";
export var btnGroup = "style-mod--btn-group--rD6KH";
export var productsGridColorBtn = "style-mod--products-grid-color-btn--2SozF";
export var btnActive = "style-mod--btn-active--1nuKd";
export var shopBtn = "style-mod--shop-btn--2OBXi";
export var btnSecondary = "style-mod--btn-secondary--2d5hN";