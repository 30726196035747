// extracted by mini-css-extract-plugin
export var mixedCategories = "style-mod--mixed-categories--g08f6";
export var mcContent = "style-mod--mc-content--yx93z";
export var cTitle = "style-mod--c-title--TiYaj";
export var cSubtitle = "style-mod--c-subtitle--rQ1Wp";
export var cBtn = "style-mod--c-btn--1Y5Ze";
export var btnIsActive = "style-mod--btn-is-active--k7aJ9";
export var mcTitle = "style-mod--mc-title--2ewvK";
export var mcRow = "style-mod--mc-row--ww8cm";
export var mcCard = "style-mod--mc-card--20IPO";
export var mcImg = "style-mod--mc-img--3RMB9";
export var mcBody = "style-mod--mc-body--2-6S5";
export var mcPrice = "style-mod--mc-price--OxbgT";
export var btnGroup = "style-mod--btn-group--1eysj";
export var shopBtn = "style-mod--shop-btn--3Nmlp";